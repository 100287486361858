/* Regular DMSans */
@font-face {
  font-family: DMSans;
  src: url("./assets/fonts/DMSans-Regular.ttf");
  font-weight: 400;
}
/* Medium DMSans */
@font-face {
  font-family: DMSans;
  src: url("./assets/fonts/DMSans-Medium.ttf");
  font-weight: 600;
}
/* Bold DMSans */
@font-face {
  font-family: DMSans;
  src: url("./assets/fonts/DMSans-Bold.ttf");
  font-weight: 700;
}
/* Regular Gothic */
@font-face {
  font-family: Gothic;
  src: url("./assets/fonts/GOTHIC.TTF");
  font-weight: 400;
}
/* Bold Gothic */
@font-face {
  font-family: Gothic;
  src: url("./assets/fonts/GOTHICB.TTF");
  font-weight: 700;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'DMSans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  height: 100%;
}
/* width */
::-webkit-scrollbar {
  width: 9px;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #fff; 
  border-radius: 10px;
  background: #fff; 
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888787; 
  border-radius: 10px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}